import React, { useEffect, useState } from 'react'
import {
  SimpleForm,
  TextInput,
  ImageField,
  required,
  NumberInput,
  ImageInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  SelectInput,
  FunctionField,
  ArrayInput,
  SimpleFormIterator,
  SelectArrayInput,
  useDataProvider,
} from 'react-admin'
import imageNotFound from '../../assets/not-found.jpg'
import DeleteWithConfirmButton from '../Common/DeleteWithConfirmButton'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    {!window.location.href.includes('/create') && (
      <div>
        <DeleteWithConfirmButton
          undoable={false}
          textConfirm={'Delete company'}
          subTextConfirm={'If you delete company, you also delete all users of this company. Are you sure?'}
        />
      </div>
    )}
  </Toolbar>
)

export const CompanyForm = props => {
  const dataProvider = useDataProvider()
  const [previewPath, setPreviewPath] = useState('')
  const [feePools, setFeePools] = useState([])
  const [models, setModels] = useState([])
  const [algos, setAlgos] = useState([])
  const [ourPools, setOurPools] = useState([])

  useEffect(() => {
    getFeePools()
    getModels()
    getOurPools()
  }, [])

  const feeValidation = value => {
    if (!value) {
      return 'Required'
    }
    if (value < 0) {
      return 'Must be over 0'
    }
    return null
  }

  const handleImageChange = fileObj => {
    setPreviewPath(fileObj)
  }

  const fetchItems = async source => {
    const response = await dataProvider.getList(source, {
      pagination: {
        page: 1,
        perPage: 100,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
    })
    return response.data
  }

  const getFeePools = async () => {
    const data = await fetchItems('main_pools')
    setFeePools(data)
  }

  const getModels = async () => {
    const data = await fetchItems('models')
    setModels(data)
  }

  const getOurPools = async () => {
    const data = await fetchItems('our_pools')
    setOurPools(data)
  }

  const handleModelsChange = value => {
    const algos = []
    value.forEach(model => {
      const algo = models.find(mdl => mdl['@id'] === model)?.algo
      if (algo) {
        algos.push(algo)
      }
    })
    const set = new Set(algos)
    const res = Array.from(set)
    setAlgos(res)
  }

  useEffect(() => {
    if (!algos.length && props.record?.allowedModels?.length) handleModelsChange(props.record.allowedModels)
  }, [models])

  const getIRI = v => (typeof v === 'object' ? v['@id'] : v)

  const optionalFlags = [
    {
      id: 'nicehash',
      name: 'nicehash',
    },
    {
      id: 'viabtc',
      name: 'viabtc',
    },
  ]

  return (
    <SimpleForm {...props} redirect={null} toolbar={<CustomToolbar />}>
      <TextInput source='name' label='Name' validate={required()} />
      <TextInput source='prefix' label='Prefix' validate={required()} />
      <BooleanInput label='Append Suffix to Prefix' source='appendModelToPrefix' />
      <NumberInput
        source='ourFee'
        label='Our fee'
        inputProps={{
          step: 0.5,
          min: 0.0,
          max: 10000000.0,
        }}
        validate={feeValidation}
      />
      <TextInput source='country' label='Country' validate={required()} />
      <SelectArrayInput
        source='allowedModels'
        label='Models'
        validate={required()}
        choices={models}
        onChange={e => handleModelsChange(e.target.value)}
        // format={v => {
        //   if (models.length && !algos?.length && v) {
        //     return handleModelsChange(v)
        //   }
        //   return v
        // }}
      />
      {algos.map((algo, index) => [
        algos.length > 1 ? <Divider /> : null,
        <SelectInput
          source={`mainPoolsByAlgo[${algo}]`}
          key={index}
          label={`Main Pool (${algo})`}
          choices={feePools.filter(pool => pool.algo === algo)}
          allowEmpty
          initialValue={null}
          emptyText='Default'
          emptyValue={null}
          format={v => (v && typeof v === 'object' ? v['@id'] : v)}
        />,
        <ArrayInput label={`SSL Pools (${algo})`} source={`feePools(${algo})`}>
          <SimpleFormIterator>
            <SelectInput
              source='ourPool'
              label='name'
              validate={required()}
              choices={ourPools.filter(pool => pool.algo === algo)}
              parse={getIRI}
              format={getIRI}
            />
            <TextInput source='worker' label='Worker' validate={required()} />
            <TextInput source='password' label='Password' validate={required()} />
            <NumberInput
              source='fee'
              label='Fee'
              inputProps={{
                step: 0.5,
                min: 0.0,
                max: 10000000.0,
              }}
              validate={feeValidation}
            />
          </SimpleFormIterator>
        </ArrayInput>,
      ])}
      {!previewPath ? (
        <FunctionField
          label='Logo'
          source='logo'
          render={file => {
            return (
              <img
                src={file.logo ? `${process.env.REACT_APP_ENTRYPOINT}/uploads/${file.logo.path}` : imageNotFound}
                alt='logo'
                style={{ maxWidth: '250px' }}
              />
            )
          }}
        />
      ) : null}
      <ImageInput source='logo' label='' accept='image/*' onChange={handleImageChange}>
        <ImageField source='file' title='title' />
      </ImageInput>
      <SelectInput
        source='overrideUserPool'
        choices={optionalFlags}
        initialValue={null}
        emptyValue={null}
        emptyText='None'
        allowEmpty
        resettable
      />
      <BooleanInput label='tuneOnFly' source='tuneOnFly' />
    </SimpleForm>
  )
}
