import React, { Component } from 'react'
import { Admin, Resource, Layout, AppBar, Login } from 'react-admin'
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation'
import { fetchHydra as baseFetchHydra } from '@api-platform/admin'
import authProvider, { refresh } from './authProvider'
import { createMuiTheme } from '@material-ui/core/styles'

import { LinearProgress } from '@material-ui/core'

import AppMenu from './AppMenu'

import WebantHydraClient from './WebantHydraClient'

import PeopleIcon from '@material-ui/icons/People'
import CompanyIcon from '@material-ui/icons/Business'
import ConfigIcon from '@material-ui/icons/Build'
import ReadyIcon from '@material-ui/icons/Cloud'
import ModelIcon from '@material-ui/icons/Tab'

import { ListUser } from './Component/Users/ListUser'
import { ShowUser } from './Component/Users/ShowUser'
import { EditUser } from './Component/Users/EditUser'
import { CreateUser } from './Component/Users/CreateUser'

import { ListCompanies } from './Component/Companies/ListCompanies'
import { ShowCompany } from './Component/Companies/ShowCompany'
import { EditCompany } from './Component/Companies/EditCompany'
import { CreateCompany } from './Component/Companies/CreateCompany'

import { ListConfigs } from './Component/Configurations/ListResults'
import { ShowConfiguration } from './Component/Configurations/ShowConfiguration'
import { EditConfiguration } from './Component/Configurations/EditConfiguration'
import { CreateConfiguration } from './Component/Configurations/CreateConfiguration'

import { ListBuilds } from './Component/Builds/ListBuilds'

import { ListModels } from './Component/Models/ListModels'
import { ShowModel } from './Component/Models/ShowModel'
import { EditModel } from './Component/Models/EditModel'
import { CreateModel } from './Component/Models/CreateModel'

import { ListBuildConfigs } from './Component/BuildConfigs/ListBuildConfigs'
import { EditBuildConfig } from './Component/BuildConfigs/EditBuildConfig'
import { CreateBuildConfig } from './Component/BuildConfigs/CreateBuildConfig'

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
})

const entrypoint = `${process.env.REACT_APP_ENTRYPOINT}/api`

const dataProvider = (api, fetchHydra) => WebantHydraClient(api, fetchHydra)
const apiDocumentationParser = (entrypoint, fetchHeaders) =>
  parseHydraDocumentation(entrypoint, {
    headers: new Headers(fetchHeaders),
  }).then(
    ({ api }) => {
      api.resources.map(() => {
        //TODO: Объединить ресурсы в массив и проверять на .some
        // if ('companies' === resource.name) {
        //   resource.fields.map(field => {
        //     if ('logo' === field.name) {

        //       field.denormalizeData = value => {
        //         return {
        //           src: value
        //         }
        //       };

        //       field.normalizeData = value => {
        //         console.log(value)
        //         if (value && value.rawFile instanceof File) {
        //           const body = new FormData();
        //           body.append('file', value.rawFile);
        //           const id = document.referrer.split('api/companies/')[1]
        //           const url = !localStorage.roles.includes('ROLE_USER') ? `${entrypoint}/profile/company/logo` : `${entrypoint}/companies/${id}/logo`
        //           return fetch(url, { body, method: 'POST', headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        //             .then(response => response.json()).then(res => res['@id']);
        //         }

        //         return value.src;
        //       };

        //       return field
        //     }
        //     return { api }
        //   })
        //   return resource
        // }
        return { api }
      })
      return { api }
    },
    result => {
      switch (result.status) {
        case 401:
          return Promise.resolve({
            api: result.api,
            customRoutes: [
              {
                props: {
                  path: '/',
                  // render: () => <Redirect to={`/login`} />
                },
              },
            ],
          })
        default:
          return Promise.reject(result)
      }
    }
  )

const LoginPage = () => <Login />

class App extends Component {
  state = {
    api: null,
    fetchHydra: null,
  }

  componentDidMount() {
    refresh().then(access_token => {
      const fetchHydra = (url, options = {}) =>
        baseFetchHydra(url, {
          ...options,
          headers: new Headers({ Authorization: `Bearer ${access_token}` }),
        })
      this.setState({ fetchHydra })
      apiDocumentationParser(entrypoint, {
        Authorization: `Bearer ${access_token}`,
      })
        .then(({ api }) => {
          this.setState({ api, fetchHydra })
        })
        .catch(() => {
          this.setState({ api: null })
        })
    })
  }

  render() {
    if (!this.state.api || !this.state.fetchHydra) return <LinearProgress style={{ marginTop: 1 }} />
    return (
      <Admin
        appLayout={AppLayout}
        loginPage={LoginPage}
        api={this.state.api}
        apiDocumentationParser={apiDocumentationParser}
        dataProvider={dataProvider(this.state.api, this.state.fetchHydra)}
        theme={theme}
        authProvider={authProvider}
      >
        {permissions => {
          const isAdminOrSuperAdmin = permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN')

          return [
            <Resource
              name='users'
              list={isAdminOrSuperAdmin ? ListUser : null}
              show={ShowUser}
              edit={EditUser}
              create={isAdminOrSuperAdmin ? CreateUser : null}
              options={{ label: 'Users' }}
              icon={PeopleIcon}
            />,
            <Resource
              name='companies'
              list={isAdminOrSuperAdmin ? ListCompanies : null}
              show={ShowCompany}
              edit={isAdminOrSuperAdmin ? EditCompany : null}
              create={isAdminOrSuperAdmin ? CreateCompany : null}
              options={{ label: 'Companies' }}
              icon={CompanyIcon}
            />,
            <Resource
              name='configurations'
              list={ListConfigs}
              show={ShowConfiguration}
              edit={EditConfiguration}
              create={CreateConfiguration}
              options={{ label: 'Configurations' }}
              icon={ConfigIcon}
            />,
            <Resource name='build_groups' list={ListBuilds} options={{ label: 'Builds' }} icon={ReadyIcon} />,
            <Resource
              name='models'
              list={isAdminOrSuperAdmin ? ListModels : null}
              show={ShowModel}
              edit={EditModel}
              create={CreateModel}
              options={{ label: 'Models' }}
              icon={ModelIcon}
            />,
            <Resource
              edit={isAdminOrSuperAdmin ? EditBuildConfig : null}
              list={isAdminOrSuperAdmin ? ListBuildConfigs : null}
              create={isAdminOrSuperAdmin ? CreateBuildConfig : null}
              name='param_values'
              options={{ label: 'Build configs' }}
            />,
            <Resource name='build_types' />,
            <Resource name='builds' />,
            <Resource name='our_pools' />,
            <Resource name='config_param_definitions' />,
            <Resource name='config_param_options' />,
            <Resource name='main_pools' />,
          ]
        }}
      </Admin>
    )
  }
}

const MinerAppBar = props => <AppBar {...props} userMenu={<AppMenu />} />

const AppLayout = props => <Layout {...props} appBar={MinerAppBar} />

export default App
