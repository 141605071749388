import React from 'react';
import {Create} from 'react-admin';
import { transfromCompanies } from '../utils/transformCompanies';
import {CompanyForm} from './_Form';

export const CreateCompany = (props) => {
  return (
    <Create {...props} undoable={false} transform={transfromCompanies}>
      <CompanyForm {...props} />
    </Create>
  )
}

