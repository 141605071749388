const client_id = `${process.env.REACT_APP_CLIENT_ID}`
const client_secret = `${process.env.REACT_APP_CLIENT_SECRET}`

export const baseUrl = `${process.env.REACT_APP_ENTRYPOINT}/oauth/v2/token?client_id=${client_id}&client_secret=${client_secret}`

const authProvider = {
  login: ({ username, password }) => {
    const authenticationTokenUri = `${process.env.REACT_APP_ENTRYPOINT}/oauth/v2/token`

    const formData = new FormData()

    formData.append('grant_type', 'password')
    formData.append('username', username)
    formData.append('password', password)
    formData.append('client_id', client_id)
    formData.append('client_secret', client_secret)

    return fetch(authenticationTokenUri, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: formData,
    })
      .then(response => {
        if (response.status < 200 || response.status >= 300) throw new Error(response.statusText)
        return response.json()
      })
      .then(({ access_token, refresh_token, expires_in }) => {
        const current = new Request(`${process.env.REACT_APP_ENTRYPOINT}/api/users/current`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        fetch(current)
          .then(res => res.json())
          .then(({ roles, email, username, id, company }) => {
            const exprIn = new Date().getTime() + expires_in * 1000

            localStorage.setItem('roles', JSON.stringify(roles))
            localStorage.setItem('username', username)
            localStorage.setItem('email', email)

            localStorage.setItem('user_id', id)
            localStorage.setItem('company_id', company ? company['@id'].split('/')[3] : null)
            localStorage.setItem('access_token', access_token)
            localStorage.setItem('refresh_token', refresh_token)
            localStorage.setItem('expired_at', exprIn)
            JSON.stringify(roles).includes('ROLE_ADMIN') || JSON.stringify(roles).includes('ROLE_SUPER_ADMIN')
              ? window.location.replace('./admin')
              : window.location.replace('./admin#/builds')
          })
      })
  },
  logout: () => {
    localStorage.clear()
    return Promise.resolve()
  },
  checkError: error => {
    const status = error.status
    if (status === 401 || status === 403) {
      localStorage.clear()
      return Promise.reject()
    }
    if (status === 401) {
      refresh()
    }
    return Promise.resolve()
  },
  checkAuth: () => {
    return localStorage.getItem('access_token') ? Promise.resolve() : Promise.reject()
  },
  getPermissions: () => {
    const role = localStorage.getItem('roles')
    return role ? Promise.resolve(role) : Promise.reject()
  },
}

export default authProvider

export const refresh = () => {
  return new Promise((resolve, reject) => {
    const refreshToken = localStorage.getItem('refresh_token')

    if (refreshToken) {
      const refresh = new Request(`${baseUrl}&grant_type=refresh_token&refresh_token=${refreshToken}`, {
        method: 'GET',
      })
      fetch(refresh)
        .then(res => res.json())
        .then(
          ({
            access_token,
            refresh_token,
            // expires_in
          }) => {
            // TODO: Может пригодиться.
            // const exprIn = new Date().getTime() + expires_in * 1000

            localStorage.setItem('access_token', access_token)
            localStorage.setItem('refresh_token', refresh_token)

            resolve(access_token)
          }
        )
    } else {
      resolve(localStorage.getItem('access_token'))
    }
  })
}
